import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => {

  const h2OverBorderLeft = () => {
    return (
      <span className="hidden lg:block h-3gap relative">
        <span className="absolute right-0 top-0 w-screen h-1gap border border-black border-t-1gap xl:mr-0"></span>
        <span className="hidden xl:block absolute right-0 top-0 border-black border-1gap rounded-full -mt-1/2gap mr-0 xl:-mr-1gap"></span>
      </span>
    )
  }

  const sideImgBorderRight = () => {
    return (
      <>
        <span className="hidden xl:block absolute left-0 top-0 ml-2gap xl:ml-1col1gap h-full border border-black border-l-1gap"></span>
        <span className="hidden lg:block absolute left-0 top-0 ml-2gap xl:ml-1col1gap w-screen border border-black border-t-1gap"></span>
      </>
    )
  }

  const h2OverBorderRight = () => {
    return (
      <span className="hidden lg:block h-3gap relative">
        <span className="absolute left-0 top-0 w-screen h-1gap border border-black border-t-1gap xl:ml-0"></span>
        <span className="hidden xl:block absolute left-0 top-0 border-black border-1gap rounded-full -mt-1/2gap -ml-1gap xl:-ml-1gap"></span>
      </span>
    )
  }

  const sideImgBorderLeft = () => {
    return (
      <>
        <span className="hidden xl:block absolute right-0 top-0 h-full border border-black border-r-1gap"></span>
        <span className="hidden lg:block absolute right-0 top-0 w-screen border border-black border-t-1gap"></span>
      </>
    )
  }

  const breadPaths = [
    {
      href: 'http://example.com',
      text: 'path1'
    },
    {
      href: 'http://example.com',
      text: 'path2'
    },
    {
      href: 'http://example.com',
      text: 'path3'
    },
    {
      text: 'path4'
    }
  ]

  return (
    <Layout breadPaths={breadPaths}>

      <SEO title="Home" />

      <h1 className="text-4xl md:text-5xl leading-tight font-bold py-2gap lg:py-1col">
        <span className="whitespace-no-wrap">ドイツ美大</span>
        <span className="whitespace-no-wrap">オンラインクラス</span>
      </h1>

      <div className="border-black border-t-1/2gap pt-1gap lg:hidden"></div>

      <section className="flex -ml-2gap xl:-ml-1col1gap flex-col md:flex-row">
        <div className="flex-grow pl-2gap xl:pl-1col1gap pb-1gap lg:pb-1col">
          <h2 className="text-4xl leading-tight pb-1gap">{h2OverBorderLeft()}概要</h2>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
        </div>
        <div className="flex-grow-0 hidden sm:block relative pb-2gap">
          <div className="pl-2gap xl:pl-1col1gap w-3col2gap md:w-2col3gap lg:w-3col2gap xl:w-5col4gap mx-auto">
            <div className="over:w-5col4gap over:h-5col4gap">
              <img className="block w-full border-black border-1/2gap lg:border-1gap" src="https://placekitten.com/800/800" alt="" />
            </div>
          </div>
          {sideImgBorderRight()}
        </div>
      </section>

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex -ml-2gap xl:-ml-1col1gap flex-col md:flex-row-reverse">
        <div className="flex-grow pl-2gap xl:pl-1col1gap pb-1gap lg:pb-1col">
          <h2 className="text-4xl leading-tight relative pb-1gap font-bold">{h2OverBorderRight()}概要</h2>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
        </div>
        <div className="flex-grow-0 hidden sm:block relative pb-2gap">
          <div className="pl-2gap xl:pl-1col1gap w-3col2gap md:w-2col3gap lg:w-3col2gap xl:w-5col4gap mx-auto">
            <div className="over:w-5col4gap over:h-5col4gap over:-ml-1col1gap">
              <img className="block w-full border-black border-1/2gap lg:border-1gap" src="https://placekitten.com/800/800" alt="" />
            </div>
          </div>
          {sideImgBorderLeft()}
        </div>
      </section>

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex -ml-1col1gap">
        <div className="flex-grow pl-1col1gap pb-1gap">
          <h2 className="text-4xl leading-tight relative pt-3gap pb-1gap">
            概要
            <span className="absolute right-0 top-0 w-screen h-1gap border border-black border-t-1gap"></span>
            <span className="hidden lg:block absolute right-0 top-0 border-black border-3/2gap rounded-full -mt-1gap -mr-1gap"></span>
          </h2>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
        </div>
        <div className="flex-grow-0 hidden md:block relative pb-2gap">
          <div className="pl-1col1gap md:w-3col2gap lg:w-4col3gap xl:w-5col4gap">
            <img className="block w-full border-black border-1gap" src="https://placekitten.com/800/800" alt="" />
          </div>
          <span className="absolute left-0 top-0 ml-1col1gap h-full border border-black border-l-1gap"></span>
          <span className="absolute left-0 top-0 ml-1col1gap w-screen border border-black border-t-1gap"></span>
        </div>
      </section>

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>

      <section className="flex -ml-1col1gap flex-row-reverse">
        <div className="flex-grow pl-1col1gap pb-1gap">
          <h2 className="text-4xl leading-tight relative pt-3gap pb-1gap">
            概要
            <span className="absolute left-0 top-0 w-screen h-1gap border border-black border-t-1gap"></span>
            <span className="hidden lg:block absolute left-0 top-0 border-black border-3/2gap rounded-full -mt-1gap -ml-2gap"></span>
          </h2>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
          <p className="pb-6">彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。 彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。</p>
        </div>
        <div className="flex-grow-0 hidden md:block relative pb-2gap">
          <div className="pl-1col1gap md:w-3col2gap lg:w-4col3gap xl:w-5col4gap">
            <img className="block w-full border-black border-1gap" src="https://placekitten.com/800/800" alt="" />
          </div>
          <span className="absolute right-0 top-0 ml-1col1gap h-full border border-black border-l-1gap"></span>
          <span className="absolute right-0 top-0 ml-1col1gap w-screen border border-black border-t-1gap"></span>
        </div>
      </section>

      <div className="border-black border-t-1/2gap pt-2gap lg:hidden"></div>




      <div className="debug-bar" style={{ background: 'orange' }}>DEBUG_BAR</div>

      <div className="flex -ml-1gap mb-16">
        <div className="flex-grow pl-1gap">
          <h2 className="text-4xl leading-tight pt-5 pb-6 border-black border-t-1gap relative breaking-layout-heading-border-left">概要</h2>
          <div className="yw-RichTextSlices">
            <p>
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。
            </p>
            <h2>それとも背後の足音の主は</h2>
            <p>
              彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。
              彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。
              彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。
              足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。
              突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。
            </p>
            <ol>
              <li>彼は暗闇の中で道を確かめようとじっと見つめた</li>
              <li>べての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのかべての苦労と準備は水の泡だというのか</li>
              <li>追跡者に見付けられないことを願った</li>
            </ol>
            <p>
              彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。
              彼は素早く右に身を翻し、建物の間に消え去った。その時彼は、もう少しで道の真中に転がっていたごみバケツに躓き転ぶところだった。
              彼は暗闇の中で道を確かめようとじっと見つめた。どうやら自分の通ってきた道以外にこの中庭からの出道はないようだ。
              足音はだんだん近づき、彼には角を曲がる黒い人影が見えた。彼の目は夜の闇の中を必死にさまよい、逃げ道を探す。もうすべては終わりなのか。すべての苦労と準備は水の泡だというのか。
              突然、彼の横で扉が風に揺らぎ、ほんのわずかにきしむのを聞いた時、彼は背中を壁に押し付け、追跡者に見付けられないことを願った。この扉は望みの綱として投げかけられた、彼のジレンマからの出口なのだろうか。背中を壁にぴったり押し付けたまま、ゆっくりと彼は開いている扉の方へと身を動かして行った。この扉は彼の救いとなるのだろうか。
            </p>
            <ul>
              <li>彼は暗闇の中で道を確かめようとじっと見つめた</li>
              <li>べての苦労と準備は水の泡だというのか</li>
              <li>追跡者に見付けられないことを願った</li>
            </ul>
            <p>
              彼は背後にひそかな足音を聞いた。それはあまり良い意味を示すものではない。誰がこんな夜更けに、しかもこんな街灯のお粗末な港街の狭い小道で彼をつけて来るというのだ。人生の航路を捻じ曲げ、その獲物と共に立ち去ろうとしている、その丁度今。
              彼のこの仕事への恐れを和らげるために、数多い仲間の中に同じ考えを抱き、彼を見守り、待っている者がいるというのか。
            </p>
            <h2>強靭な罰をすぐにも</h2>
            <p>
              それとも背後の足音の主は、この街に無数にいる法監視役で、強靭な罰をすぐにも彼の手首にガシャンと下すというのか。彼は足音が止まったことに気が着いた。あわてて辺りを見回す。ふと狭い抜け道に目が止まる。
              彼は素早く右に身を翻し、建物の間に消え去った。
            </p>
            <p></p>
            <p className="block-img">
              <img
                src="https://images.prismic.io/slicemachine-blank/6b2bf485-aa12-44ef-8f06-dce6b91b9309_dancing.png?auto=compress,format"
                alt=""
              />
            </p>
          </div>

        </div>
        <div className="flex-grow-0 hidden md:block">
          <div className="pl-1gap md:w-2col2gap lg:w-3col3gap xl:w-4col4gap">
            <img className="block w-full" src="https://placekitten.com/600/600" alt="" />
          </div>
        </div>
      </div>

      <div className="debug-bar" style={{ background: 'orange' }}>DEBUG_BAR</div>
    

    </Layout>
  )

};

export default Page
